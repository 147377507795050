var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "分类筛选",
      "left-text": "返回",
      "left-arrow": ""
    },
    on: {
      "click-left-callback": _vm.handleClickLeftCallback
    }
  }), _vm.list.length > 0 ? _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-tree-select', {
    attrs: {
      "height": "calc(100vh - 46px)",
      "items": _vm.list,
      "main-active-index": _vm.active
    },
    on: {
      "update:mainActiveIndex": function updateMainActiveIndex($event) {
        _vm.active = $event;
      },
      "update:main-active-index": function updateMainActiveIndex($event) {
        _vm.active = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.list[_vm.active].subs.length > 0 ? _vm._l(_vm.list[_vm.active].subs, function (category, index) {
          return _c('van-cell-group', {
            key: index,
            staticClass: "group",
            attrs: {
              "title": category.text
            }
          }, [category.subs.length === 0 ? _c('van-grid', {
            attrs: {
              "column-num": 3,
              "square": "",
              "clickable": ""
            }
          }, [_c('van-grid-item', {
            attrs: {
              "text": category.text
            },
            on: {
              "click": function click($event) {
                return _vm.handleFilter(category);
              }
            }
          })], 1) : _c('van-grid', {
            attrs: {
              "column-num": 3,
              "square": "",
              "clickable": ""
            }
          }, _vm._l(category.subs, function (subCategory, index2) {
            return _c('van-grid-item', {
              key: index2,
              attrs: {
                "text": subCategory.text
              },
              on: {
                "click": function click($event) {
                  return _vm.handleFilter(subCategory);
                }
              }
            });
          }), 1), _c('div', {
            staticStyle: {
              "height": "10px",
              "background-color": "#f2f2f2"
            }
          })], 1);
        }) : _c('van-cell-group', {
          attrs: {
            "title": _vm.list[_vm.active].text
          }
        }, [_c('van-grid', {
          attrs: {
            "column-num": 3,
            "square": "",
            "clickable": ""
          }
        }, [_c('van-grid-item', {
          attrs: {
            "text": _vm.list[_vm.active].text
          },
          on: {
            "click": function click($event) {
              return _vm.handleFilter(_vm.list[_vm.active]);
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 3276726281)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }