<template>
  <div>
    <my-nav-bar
      title="分类筛选"
      left-text="返回"
      left-arrow
      @click-left-callback="handleClickLeftCallback"
    />
    <div v-if="list.length > 0" class="app-main-container">
      <van-tree-select height="calc(100vh - 46px)" :items="list" :main-active-index.sync="active">
        <template #content>
          <template v-if="list[active].subs.length > 0">
            <van-cell-group v-for="(category, index) in list[active].subs" :key="index" class="group" :title="category.text">
              <van-grid v-if="category.subs.length === 0" :column-num="3" square clickable>
                <van-grid-item :text="category.text" @click="handleFilter(category)" />
              </van-grid>
              <van-grid v-else :column-num="3" square clickable>
                <van-grid-item v-for="(subCategory, index2) in category.subs" :key="index2" :text="subCategory.text" @click="handleFilter(subCategory)" />
              </van-grid>
              <div style="height: 10px; background-color: #f2f2f2;" />
            </van-cell-group>
          </template>
          <van-cell-group v-else :title="list[active].text">
            <van-grid :column-num="3" square clickable>
              <van-grid-item :text="list[active].text" @click="handleFilter(list[active])" />
            </van-grid>
          </van-cell-group>
        </template>
      </van-tree-select>
    </div>
  </div>
</template>

<script>
import myNavBar from '../../components/my-nav-bar.vue'
import { getCategories } from '@/api/warehouse_goods'
export default {
  name: 'WarehouseGoodsFilter',
  components: { myNavBar },
  data() {
    return {
      active: 0,
      list: []
    }
  },
  activated() {
    if (this.$route.meta.scrolls && this.$route.meta.scrolls.length === 2) {
      document.querySelector('.van-sidebar').scrollTop = this.$route.meta.scrolls[0]
      document.querySelector('.van-tree-select__content').scrollTop = this.$route.meta.scrolls[1]
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$route.meta.scrolls = [document.querySelector('.van-sidebar').scrollTop, document.querySelector('.van-tree-select__content').scrollTop]
    next()
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    this.getData()
  },
  methods: {
    getData() {
      getCategories().then(res => {
        this.list = res.data
      })
    },
    handleFilter(category) {
      // this.$store.commit('setFilterCategory', category)
      // this.$router.go(-1)
      this.$router.push({
        path: '/warehouse-goods',
        query: {
          category_id: category.id
        }
      })
    },
    handleClickLeftCallback() {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
